import Biking from 'images/landing/biking.jpg';
import Snowboarding from 'images/landing/snowboarding.jpg';
import Surfing from 'images/landing/surfing.jpg';
import { useEffect, useState } from 'react';
import SearchBarLocationContainer from '../generic/search_bar_location_container';

export default function DashboardCarousel() {
  const activities = [
    {
      name: 'Surfing',
      image: Surfing,
      location: 'Honolula Bay - Maui, HI',
      date: 'March 30th, 2021 - 12:34PM',
    },
    {
      name: 'Snowboarding',
      image: Snowboarding,
      location: 'Dragon\'s Tail, Mammoth, CA',
      date: 'June 1st, 2020 - 1:12PM',
    },
    {
      name: 'Biking',
      image: Biking,
      location: 'Berghaus Sulzfluh, Saint Antönien, Switzerland',
      date: 'April 16th, 2020 - 10:50AM'
    }
  ];

  const [activity, setActivity] = useState(activities[0]);
  const [num, setNum] = useState(0);

  useEffect(() => {
    const cycle = setInterval(() => {
      setActivity(activities[(num+1) % activities.length]);
      setNum(num+1);
    }, 5000)

    return () => clearInterval(cycle);
  });

  const searchHeroStyles = {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${activity.image}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className='search-hero' style={searchHeroStyles}>
      <div className='search-hero-inner'>
        <h2>Find awesome photos of you <strong>{activity.name}.</strong></h2>
        {/* <p>PLEASE ENTER: <span>LOCATION | DATE &#38; TIME | ACTIVITY</span></p> */}
        {/* <SearchBarContainer /> */}
        <SearchBarLocationContainer />
        <div className='hero-image-meta'>
          <p>{activity.location} - {activity.name}</p>
          <hr />
          <p>{activity.date}</p>
        </div>
      </div>
    </div>
  );
}
