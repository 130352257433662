import { ReactElement, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Order } from '../../types';
import { getUserOrders } from '../../util/api/order_api_util';
import GenericLoading from '../generic/generic_loading';
import SEO from '../generic/seo';
import CartIcon from '../icons/cart_icon';
import {
  Collection,
  Detail,
  Heading,
  Metadata,
  Panel
} from '../library';

export default function PastOrders(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const alert = useAlert();

  useEffect(() => {
    getUserOrders()
      .then((ordersResponse: Order[]) => {
        setOrders(ordersResponse);
      })
      .then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <GenericLoading />;
  }

  if (!orders.length) {
    return (
      <Panel className="empty">
        <CartIcon width="76" height="81" />
        <Heading level={1}>You have not purchased any photos yet</Heading>
        <p>Your past orders will be available for download here.</p>
      </Panel>
    );
  }

  return (
    <>
      <SEO title='Past Orders' />
      <Panel semantic element="article" className="pastOrders">
        <Panel.Header>
          <Heading level={1}>Past Orders</Heading>
        </Panel.Header>
        <Panel.Body>
          <Collection items={orders.sort((a, b) => (a.checkoutCompleted > b.checkoutCompleted) ? 1 : -1)} keyName="id">
            {({ completedAt, wunderpicsOrderId, lineItems, total }) => (
              <Panel semantic className="orderPanel">
                <Panel.Header>
                  <Metadata className="order">
                    <div className="order__details">
                      <Detail label="Order #:">{wunderpicsOrderId}</Detail>
                      <Detail label="Purchase Date:">{completedAt}</Detail>
                      <Detail label="Total:">{`$${total}`}</Detail>
                      <Detail label="# Of Photos:">{lineItems.length}</Detail>
                    </div>
                    {/* <div className="order__actions">
                      <a href="#">View Invoice</a>
                      <Button
                        className="downloadAllButton"
                        onClick={() => alert('Download All Photos in Order')}
                      >
                        Download Photos
                      </Button>
                    </div> */}
                  </Metadata>
                </Panel.Header>
                <Panel.Body>
                  <Collection items={lineItems} keyName="productId">
                    {({
                      activityName,
                      height,
                      locationName,
                      name,
                      photoDate,
                      photographer,
                      photoTime,
                      price,
                      productId,
                      size,
                      thumbnailUrl,
                      width,
                    }) => (
                      <>
                        <Metadata className="lineItem">
                          <img
                            loading="lazy"
                            className="thumbnail"
                            src={thumbnailUrl}
                            alt={name}
                          />
                          <div className="lineItem__details">
                            <Detail label="File Name:">{name}</Detail>
                            <Detail label="Photographer:">{photographer}</Detail>
                            <Detail label="Location:">{locationName}</Detail>
                            <Detail label="Date & Time:">{`${photoDate} | ${photoTime}`}</Detail>
                            <Detail label="Activity:">{activityName}</Detail>
                            <Detail label="Size:">{`${width}x${height} | ${size}`}</Detail>
                            <Detail>
                              <a href="#">Personal Use License</a>
                            </Detail>
                          </div>
                          <div className="lineItem__actions">
                            <Detail label="Price:">{`$${price}`}</Detail>
                            <a href={`/api/photos/download/${productId}`}
                              onClick={() => alert.success(`Downloading photo: ${name}. Please wait a few seconds and then check your downloads folder.`) }
                              className='downloadAllButton'
                              download={name}
                            >
                              Download
                            </a>
                          </div>
                        </Metadata>
                        <hr />
                      </>
                    )}
                  </Collection>
                </Panel.Body>
              </Panel>
            )}
          </Collection>
        </Panel.Body>
      </Panel>
    </>
  );
}
