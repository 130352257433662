import Biking from 'images/landing/biking.jpg';
import Snowboarding from 'images/landing/snowboarding.jpg';
import Surfing from 'images/landing/surfing.jpg';
import WelcomeMap from 'images/landing/welcome-map.png';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Footer from './../footer';
import SearchBarLocationContainer from './../generic/search_bar_location_container';
import MagnifyingGlassIcon from './../icons/magnifying_glass_icon';
import MoneyIcon from './../icons/money_icon';
import PictureTimeIcon from './../icons/picture_time_icon';

class Landing extends React.Component {
  activities = [
    {
      name: 'Surfing',
      image: Surfing,
      location: 'Honolula Bay - Maui, HI',
      date: 'March 30th, 2021 - 12:34PM',
    },
    {
      name: 'Snowboarding',
      image: Snowboarding,
      location: 'Dragon\'s Tail, Mammoth, CA',
      date: 'June 1st, 2020 - 1:12PM',
    },
    {
      name: 'Biking',
      image: Biking,
      location: 'Berghaus Sulzfluh, Saint Antönien, Switzerland',
      date: 'April 16th, 2020 - 10:50AM'
    }
  ];

  constructor(props) {
    super(props);

    this.state = {
      location: props.searchLocation || '',
      // activities: this.activities,
      activity: this.activities[0],
    };

    this.handleLandingLocation = this.handleLandingLocation.bind(this);
    this.submitLandingLocation = this.submitLandingLocation.bind(this);
    this.activitiesCycle = this.activitiesCycle.bind(this);
  }

  componentDidMount() {
    this.cycle = this.activitiesCycle();
  }

  componentWillUnmount() {
    clearInterval(this.cycle);
  }

  activitiesCycle() {
    // const { activities } = this.state;
    let num = 0;
    return setInterval(() => {
      this.setState(
        { activity: this.activities[(num+1) % this.activities.length] },
        () => num = num+1
      )}, 5000)
  }

  handleLandingLocation(location) {
    this.setState({ location });
    this.props.setSearchLocation(location.value);
  }

  submitLandingLocation() {
    if (this.state.location) {
      this.props.history.push('/search');
    }
  }

  render() {
    const {
      activity,
    } = this.state;

    const searchHeroStyles = {
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${activity.image}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };

    return (
      <div className='landing-main'>
        <div className='search-hero' style={searchHeroStyles}>
          <div className='search-hero-inner'>
            <h2>Find awesome photos of you <strong>{activity.name}.</strong></h2>
            <SearchBarLocationContainer />
            {/* <SearchBarContainer /> */}
            <p className='start-selling-hero'>
              Are you a photographer?<br/>
              <a href='/users/sign_up'>Start Selling Today</a>
            </p>
            <div className='hero-image-meta'>
              <p>{activity.location} - {activity.name}</p>
              <hr />
              <p>{activity.date}</p>
            </div>
          </div>
        </div>
        <div className='how-it-works row no-gutters'>
          <div className='col-12'>
            <h2>How WunderPics works</h2>
            <div className='how-it-works-video-container'>
              <iframe
                className='how-it-works-video'
                src="https://player.vimeo.com/video/579595341?h=9ee1cc976d"
                width="640"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>
        <div className='welcome row no-gutters'>
          <div className='col-lg-6 col-xs-12 order-xs-2'>
            <div className='welcome-inner-left'>
              <span>Welcome to</span>
              <h3>WunderPics</h3>
              <p>A revolutionary way to buy and sell photos</p>
              <ul>
                <li>
                  <div className='welcome-card'>
                    <PictureTimeIcon />
                    <p>Every image is searchable by the location, date, and activity</p>
                  </div>
                </li>
                <li>
                  <div className='welcome-card'>
                    <MagnifyingGlassIcon />
                    <p>Users can easily find awesome photos of themselves taken by professionals</p>
                  </div>
                </li>
                <li>
                  <div className='welcome-card'>
                    <MoneyIcon />
                    <p>Photographers get more views and gain access to more customers</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-6 col-xs-12 order-xs-1'>
            <div className='welcome-inner-right'>
              <img src={WelcomeMap} />
              <br />
              <a href='/users/sign_up' className='btn black-button'>Sign Up</a>
            </div>
          </div>
        </div>
        <div className='buyer-seller row no-gutters'>
          <div className='col-lg-6 col-xs-12 seller-side'>
            <div className='buy-sell-content'>
              <h3>Sell Photos</h3>
              <ul>
                <li>Upload your photos for free</li>
                <li>Set your price</li>
                <li>Gain access to a much broader audience</li>
                <li>Say goodbye to handing out business cards</li>
              </ul>
              <a href='/users/sign_up' className='btn black-button'>Start Selling</a>
            </div>
          </div>
          <div className='col-lg-6 col-xs-12 buyer-side'>
            <div className='buy-sell-content'>
              <h3>Buy Photos</h3>
              <ul>
                <li>Find professional photos of yourself doing what you love</li>
                <li>Easily search for photos from around the world</li>
                <li>Instantly download your images and share them on social media</li>
                <li>No more tracking down photographers</li>
              </ul>
              <a href='/users/sign_up' className='btn black-button'>Sign Up</a>
            </div>
          </div>
        </div>
        <div className='how-it-works'>
          {/* <h3>What People are Saying</h3> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Landing);
