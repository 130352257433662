import { ReactElement, useEffect, useState } from 'react';
import { Payout, PayoutsResponse } from '../../types';
import { getUserPayouts } from '../../util/api/payouts_api_util';
import SEO from '../generic/seo';
import MoneyIcon from '../icons/money_icon';
import { Collection, Detail, Heading, Metadata, Panel } from '../library';
import SalesSummaryHeader from './sales_summary_header';
import GenericLoading from '../generic/generic_loading';

export default function SalesReport(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [photosSold, setPhotosSold] = useState<PayoutsResponse['photosSold']>(0);
  const [totalProfit, setTotalProfit] = useState<PayoutsResponse['totalProfit']>('$0.00');
  const [balance, setBalance] = useState<PayoutsResponse['balance']>('$0.00');

  useEffect(() => {
    getUserPayouts().
      then((userPayoutsResponse: PayoutsResponse) => {
        setPayouts(userPayoutsResponse.payouts)
        setPhotosSold(userPayoutsResponse.photosSold);
        setTotalProfit(userPayoutsResponse.totalProfit);
        setBalance(userPayoutsResponse.balance)
      }).
      then(() => setIsLoading(false))
  }, []);

  if (isLoading) {
    return <GenericLoading />;
  }

  if (!payouts.length) {
    return (
      <>
        <SEO title='Sales Report' />
        <Panel className="sales-container">
          <Panel.Header>
            <SalesSummaryHeader
              photosSold={0}
              totalProfit={totalProfit}
              balance={balance}
            />
          </Panel.Header>
          <Panel.Body className='empty'>
            <MoneyIcon width="75" height="75" />
            <Heading level={1}>You have not sold any photos yet</Heading>
            <p>A report of your earnings will appear here.</p>
          </Panel.Body>
        </Panel>
      </>
    );
  }

  return (
    <>
      <SEO title='Sales Report' />
      <Panel className='sales-container'>
        <Panel.Header>
          <Heading level={2}>Sales Summary</Heading>
          <SalesSummaryHeader
            photosSold={photosSold}
            totalProfit={totalProfit}
            balance={balance}
          />
        </Panel.Header>
        <Panel.Body>
          <Collection items={payouts}>
            {({
              wunderPicsOrderId,
              product,
              profit,
              purchaseDate,
              transferredAt
            }) => (
              <Panel className='payout-panel'>
                <Panel.Body>
                  <Metadata className='payout-inner'>
                    <img className='thumbnail' src={product.thumbnailUrl} />
                    <div className='payout-details'>
                      <Detail label='Photo:'>{product.productName}</Detail>
                      <Detail label='Location:'>{product.locationName}</Detail>
                      <Detail label='Date &amp; Time:'>{product.captureDate}</Detail>
                      <Detail label='File:'>{product.width}x{product.height} | {product.fileSize}</Detail>
                      <Detail><a href='#'>Personal Use License</a></Detail>
                    </div>
                    <div className='payout-actions'>
                      <Detail label='Order ID:'>{wunderPicsOrderId}</Detail>
                      <Detail label='Purchase Date:'>{purchaseDate}</Detail>
                      <Detail label='Profit:'>
                        {profit}
                        {transferredAt && <span className='payout-paid'>Paid</span>}
                      </Detail>
                    </div>
                  </Metadata>
                </Panel.Body>
              </Panel>
            )}
          </Collection>
        </Panel.Body>
      </Panel>
    </>
  )
}
