import axios from 'axios';

export const createPhoto = (photoFormData) => {
  return $.ajax({
    url: '/api/photos',
    method: 'POST',
    data: photoFormData,
    contentType: false,
    processData: false,
  })
};

export const downloadPhoto = (photoId) => (
  $.ajax({
    url: `/api/photos/download/${photoId}`,
    method: 'GET',
  })
);

export const updatePhotos = (photoParams) => (
  $.ajax({
    method: 'POST',
    url: `/api/photos/update_photos`,
    data: {
      updatePhotos: photoParams,
    }
  })
);

export const destroyPhotos = (ids) => (
  $.ajax({
    method: 'POST',
    url: `/api/photos/destroy_photos`,
    data: {
      ids,
    }
  })
);

export const uploadToAWS = async (file, formData) => {
  const { data: { postUrl } } = await axios.post(
    '/api/photos/pre_upload',
    formData
  );
  const options = { headers: { 'Content-Type': file.type, } };
  // file is a `File` here and axios knows how to automatically upload
  return axios.put(postUrl, file, options);
}
