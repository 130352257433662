import { ReactElement, useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import { UploadBatch } from '../../types';
import { deleteUploadBatch, getUploadBatches } from "../../util/api/upload_batch_api_util";
import GenericLoading from "../generic/generic_loading";
import SEO from '../generic/seo';
import UserStorage from '../generic/user_storage';
import UploadIcon from '../icons/upload_icon';
import { Heading, Panel } from '../library';

export default function Uploads(): ReactElement {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortDirection, setSortDirection] = useState<string>("newest");
  const [uploadBatches, setUploadBatches] = useState<UploadBatch[]>([]);

  useEffect(() => {
    getUploadBatches().
      then((uploadBatches: UploadBatch[]) => {
        setUploadBatches(uploadBatches);
      }).
      then(() => setIsLoading(false));
  }, []);

  const handleDeleteUploadBatch = (id: number) => {
    deleteUploadBatch(id);
    setUploadBatches(
      uploadBatches.filter(uploadBatch => uploadBatch.id !== id)
    );
  }

  if (isLoading) {
    return <GenericLoading />;
  }

  if (!uploadBatches.length) {
    return(
      <>
        <SEO title='Your Uploads' />
        <Panel className="empty">
          <UploadIcon width="50" height="54" />
          <Heading level={1}>You have not uploaded any photos yet</Heading>
          <p>You can view, share, and edit all of your uploads here.</p>
        </Panel>
      </>
    );
  }

  return (
    <>
      <SEO title='Your Uploads' />
      <Panel className='container'>
        <Panel.Header>
          <div className='row'>
            <div className='col-4 dashboard-card'>
              <div>
                <h2>Your Uploads</h2>
                <UserStorage />
                <Link to='/upload' className='blue-button'>Upload More Photos</Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="ml-auto">
              <label>Sort by:</label>
              <select
                onChange={(e) => {
                  setSortDirection(e.target.value);
                  console.log(e.target.value)
                }}
                className='form-control'
              >
                <option value="newest">Newest</option>
                <option value="oldest">Oldest</option>
              </select>
            </div>
          </div>
        </Panel.Header>
        <Panel.Body>
          {uploadBatches.sort((a: any, b: any) => {
            let dateA = new Date(a.uploadDate);
            let dateB = new Date(b.uploadDate);
            if (sortDirection === "newest") {
              return dateB - dateA;
            } else {
              return dateA - dateB;
            }
          }).map(({
            id,
            thumbnailUrl,
            captureBeginDate,
            captureBeginTime,
            captureEndDate,
            captureEndTime,
            locationName,
            activityName,
            uploadDate,
            numPhotos,
            batchSize,
            shareLink,
          }, i) => (
            <div key={i} className='row upload-batch'>
              <div className='col-3 upload-batch-photo'>
                <img loading="lazy" src={thumbnailUrl} />
              </div>
              <div className='col-9 upload-batch-info'>
                <div className='row'>
                  <div className='col'>
                    <p>Start</p>
                    <p>{captureBeginDate}</p>
                    <p>{captureBeginTime}</p>
                  </div>
                  <div className='col'>
                    <p>End</p>
                    <p>{captureEndDate}</p>
                    <p>{captureEndTime}</p>
                  </div>
                  <div className='col'>
                    <p>Location:</p>
                    <p>{locationName}</p>
                  </div>
                  <div className='col'>
                    <p>Activity</p>
                    <p>{activityName}</p>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-6'>
                    <p>Upload Date:</p>
                    <p>{uploadDate}</p>
                  </div>
                  <div className='col'>
                    <p># of Photos:</p>
                    <p>{numPhotos}</p>
                  </div>
                  <div className='col'>
                    <p>Upload Size:</p>
                    <p>{batchSize}</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-9'>
                    <div className='upload-share-link'>
                      {shareLink}
                    </div>
                    <button
                      className='btn btn-dark'
                      onClick={() => {
                        navigator.clipboard.writeText(shareLink);
                        alert.success('Share link successfully copied to clipboard!')
                      }}
                    >
                      Share Link
                    </button>
                  </div>
                  <div className='col'>
                    <button
                      className='btn btn-danger'
                      onClick={() => {
                        if (window.confirm(`Are you sure you want to delete this batch and all of its photos?`)) {
                          handleDeleteUploadBatch(id)
                        }
                      }}
                    >
                      Delete
                    </button>
                    <Link to={`/upload-manager?batchId=${id}`} className='btn btn-primary'>Edit Batch</Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Panel.Body>
      </Panel>
    </>
    )
}
